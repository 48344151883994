// eslint-disable-next-line import/no-cycle
import axios from 'axios';
import { NotificationManager } from 'components/common/react-notifications';
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
  adminRoot,
  goFiberUrl,
  adminRootLogin,
} from 'constants/defaultValues';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import IntlMessages from './IntlMessages';

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, "light.blueyale");
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, "rounded");
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
        localeOptions.filter(
          (x) => x.id === localStorage.getItem('currentLanguage')
        ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  let user;
  try {
    user =
      localStorage.getItem('gogo_current_user') != null
        ? JSON.parse(localStorage.getItem('gogo_current_user'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('gogo_current_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('gogo_current_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};
export const createNotification = (type, title, messages, className) => {
  const cName = className || '';
  switch (type) {
    case 'primary':
      NotificationManager.primary(
        messages,
        title,
        3000,
        null,
        null,
        cName
      );
      break;
    case 'secondary':
      NotificationManager.secondary(
        messages,
        title,
        3000,
        null,
        null,
        cName
      );
      break;
    case 'info':
      NotificationManager.info(messages, title, 3000, null, null, cName);
      break;
    case 'success':
      NotificationManager.success(
        messages,
        title,
        3000,
        null,
        null,
        cName
      );
      break;
    case 'warning':
      NotificationManager.warning(
        messages,
        title,
        3000,
        null,
        null,
        cName
      );
      break;
    case 'error':
      NotificationManager.error(
        messages,
        title,
        5000,
        null,
        null,
        cName
      );
      break;
    default:
      NotificationManager.info(messages);
      break;
  }
};

export const getToken = () => {
  return Cookies.get("Token")
}

export const logOut = (notification) => {
  if (notification == undefined) {
    createNotification("error",<IntlMessages id={"config.alert-logOut-title"} />, <IntlMessages id={"config.alert-logOut-message"} />,"filled")
  }
  Cookies.set("Token", "")
  Cookies.set("FirstName", "")
  Cookies.set("LastName", "")
  Cookies.set("IsAdmin", "")
  setTimeout(() => {
    window.location.replace(`${adminRootLogin}`)
  }, 500);

}

export const isLoggedIn = () => {
  const token = Cookies.get('Token'); // Token'ı buraya yerleştirin
  const decodedToken = jwt_decode(token);

  const expirationTime = decodedToken.exp * 1000; // Saniye cinsinden son kullanma tarihini milisaniyeye çeviriyoruz
  const currentTime = Date.now(); // Şu anki zamanı alıyoruz

  if (currentTime > expirationTime) {
    createNotification("error", <IntlMessages id={"config.alert-logOut-title"} />, <IntlMessages id={"config.alert-logOut-message"} />, "filled")
    logOut()
  }
}

export const isExpired = (expire_date) => {
  const now = moment();
  const expire = moment(expire_date);
  const diff = expire.diff(now, 'seconds');
  const duration = moment.duration(diff, 'seconds');
  const days = duration.days();
  const months = duration.months();
  const years = duration.years();

  if (years < 0 || months < 0 || days < 0) {
    return true
  } else
    return false
}


export const checkIsAdmin = async () => {

  let headers = {
    'token':getToken(),
    'Content-Type': 'multipart/form-data',
}

  let bodyFormData = new FormData()
    bodyFormData.append("customer_id", 2)

  return await axios
    .post(`${goFiberUrl}/api/user/check-is-admin`, bodyFormData,  { "headers": headers })
    .then((resp) => {
     
       return true
    })
  .catch((err) => {
    console.error(err);
    return false
})
}